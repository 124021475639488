import { render, staticRenderFns } from "./maintenanceRecordsRxDetail.vue?vue&type=template&id=786fc2bd&scoped=true&"
import script from "./maintenanceRecordsRxDetail.vue?vue&type=script&lang=js&"
export * from "./maintenanceRecordsRxDetail.vue?vue&type=script&lang=js&"
import style0 from "./maintenanceRecordsRxDetail.vue?vue&type=style&index=0&id=786fc2bd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "786fc2bd",
  null
  
)

export default component.exports
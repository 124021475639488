import axios from "@/utils/axios";
import login from "@/store/modules/login";
const userInfo = login.state.userInfo;
const otherParams = { orgCode: userInfo?.orgCode || "" };

import apiPath from "@/utils/apiPath";
const equipment = apiPath.equipment;
const contractor = apiPath.contractor;

/**
 * 工单类型下拉
 * @param params
 * @returns {*}
 */
export const getWorkorderTypeSltData = params => {
  const p = { ...params, ...otherParams };
  return axios.get(`${equipment}/select/tickets/type`, { params: p });
};

/**
 * // 获取工单状态/维修记录状态下拉 flag: 1 工单状态, 2 维修状态
 * @param params
 * @returns {*}
 */
export const getWorkorderStatusSltData = () => {
  return axios.get(`${equipment}/select/tickets/tips?type=1`);
};

/**
 * // 获取工单状态/维修记录状态下拉 flag: 1 工单状态, 2 维修状态
 * @param params
 * @returns {*}
 */
export const getMaintenanceStatusSltData = () => {
  return axios.get(`${equipment}/select/tickets/tips?type=2`);
};

/**
 * // 工单管理列表
 * @param params
 * @returns {*}
 */
export const getWorkorderListData = params => {
  return axios.get(`${equipment}/page/tickets`, { params });
};

/**
 * // 工单管理列表-待我处理
 * @param params
 * @returns {*}
 */
export const getWorkorderTodoListData = params => {
  return axios.get(`${equipment}/page/tickets/with-me`, { params });
};

/**
 * // 工单详情新增
 * @param params
 * @params flag 1 保存, 2 提交
 * @returns {*}
 */
export const addWorkOrder = (flag, params) => {
  return axios.post(`${equipment}/add/tickets/${flag}`, params);
};

/**
 * // 工单详情更新
 * @param params
 * @returns {*}
 */
export const updateWorkOrder = params => {
  return axios.post(`${equipment}/update/tickets/${params.id}`, params);
};

/**
 * // 工单详情
 * @param id
 * @returns {*}
 */
export const getWorkorderInfo = id => {
  return axios.get(`${equipment}/info/tickets/${id}`);
};
/**
 * // 通过维修记录id查询外部维修记录
 * @param id
 * @returns {*}
 */
export const getOutSideInfo = params => {
  return axios.get(`${equipment}/list/maintenance/out`, { params });
};
/**
 * 查询是否有审批权限
 * @param params
 * @returns {*}
 */
export const getTicketsPermission = params => {
  return axios.get(`${equipment}/permission/tickets`, { params });
};

/**
 * 查询当前正在执行的节点
 * @param params
 * @returns {*}
 */
export const getTicketsCurrentNode = params => {
  return axios.get(`${equipment}/info/tickets/current-node`, { params });
};

/**
 * 查询工单流程
 * @param params
 * @returns {*}
 */
export const getWorkOrderRecord = params => {
  return axios.get(`${equipment}/list/tickets/approve/record`, { params });
};

/**
 * // 审批工单接口
 * @param params
 * @returns {*}
 */
export const approveWorkOrder = params => {
  return axios.post(`${equipment}/approve/tickets`, params);
};

/**
 * // 工单详情-维修记录部门列
 * @param params
 * @returns {*}
 */
export const getMaintenanceById = params => {
  return axios.get(`${equipment}/list/maintenance`, { params });
};

/**
 * // 工单详情-维修记录详情
 * @param id
 * @returns {*}
 */
export const getMaintenanceInfo = id => {
  return axios.get(`${equipment}/info/maintenance/${id}`);
};

/**
 * // 维修记录列表
 * @param params
 * @returns {*}
 */
export const getMaintenanceByPage = params => {
  return axios.get(`${equipment}/page/maintenance`, { params });
};

/**
 * // 维修记录列表-待我处理
 * @param params
 * @returns {*}
 */
export const getMaintenanceTodoByPage = params => {
  return axios.get(`${equipment}/page/maintenance/with-me`, { params });
};

/**
 * 上传(保存)维修记录
 * @param params
 * @returns {*}
 */
export const updateMaintenanceRecord = params => {
  return axios.post(`${equipment}/update/maintenance/${params.id}`, params);
};

/**
 * 维修记录-查询是否有审批权限
 * @param params
 * @returns {*}
 */
export const getMaintenanceRecordPermission = params => {
  return axios.get(`${equipment}/permission/tickets/maintenance`, { params });
};

/**
 * 维修记录-查询当前正在执行的节点
 * @param params
 * @returns {*}
 */
export const getMaintenanceRecordCurrentNode = params => {
  return axios.get(`${equipment}/info/tickets/maintenance/current-node`, {
    params
  });
};

/**
 * 维修记录-审批维修记录
 * @param params
 * @returns {*}
 */
export const approveMaintenance = params => {
  return axios.post(`${equipment}/approve/tickets/maintenance`, params);
};

/**
 * 维修记录-查询工单流程
 * @param params
 * @returns {*}
 */
export const getMaintenanceRecordApprovedRecord = params => {
  return axios.get(`${equipment}/list/tickets/maintenance/approve/record`, {
    params
  });
};

/**
 * 设备增加备品备件弹框列表
 * @param params
 * @returns {*}
 */
export const getCheckSpare = params => {
  return axios.get(`${equipment}/select/need/reserve/part`, { params });
};

// 工单费用类型
export const getListTicketsCostType = () => {
  return axios.get(`${equipment}/list/tickets/cost/type`);
};

// 查询工单审批流节点配置
export const getTicketsApproveFieldByNodeId = id => {
  return axios.get(`${equipment}/tickets/approve/field/${id}`);
};

// 获取承包商下拉
export const getSelectContractorsNames = params => {
  return axios.get(`${contractor}/select/contractors`, { params });
};

// 查询工单的流程图
export const getTicketsProcessById = id => {
  return axios.get(`${equipment}/list/tickets/process/${id}`);
};
// 查维修记录的流程图
export const getMaintenanceProcessById = id => {
  return axios.get(`${equipment}/list/maintenance/process/${id}`);
};

/**
 * 成本中心下拉
 * @param params
 * @returns {*}
 */
export const getCostCenterSltData = () => {
  return axios.get(`${equipment}/cost/center/listMaster/noPage`);
};

<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    position="bottom"
    :style="popupStyle"
    class="select-popup-grade"
    round
    v-bind="$attrs"
    @open="onOpen"
    @opened="onOpened"
    @click-overlay="onClose"
    v-on="$listeners"
  >
    <div class="popup-con-header">
      <i
        v-if="isShowConfrimIcon"
        class="iconclose iconfont iconfont-danchuangguanbi"
        @click="onClose"
      ></i>
      <div class="popup-con-header-title">{{ title }}</div>
      <i
        v-if="isShowConfrimIcon"
        class="iconsure iconfont iconfont-danchuangqueren"
        @click="selectSure"
      ></i>
      <i
        v-if="!isShowConfrimIcon"
        class="iconright iconfont iconfont-danchuangguanbi"
        @click="onClose"
      ></i>
    </div>
    <van-search
      v-if="filterable"
      v-model="query.code"
      placeholder="请输入备件编码"
      class="search-bar"
      @search="onSearch"
      @clear="onCancel"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>
    <van-search
      v-if="filterable"
      v-model="query.name"
      placeholder="请输入备件名称"
      class="search-bar"
      @search="onSearch"
      @clear="onCancel"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>
    <div
      class="popup-list"
      :class="{ nofilter: !filterable, 'show-footer': isShowFooter }"
    >
      <div class="title-row">
        <span class="f1">备件编号</span>
        <span class="f1">备件名称</span>
        <span class="wd20">库存</span>
        <span class="wd20">单位</span>
      </div>
      <van-pull-refresh
        ref="vanList"
        v-model="refreshing"
        class="alarm-list"
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="loadError"
          error-text="加载失败，请稍后再试！"
          @load="onLoad"
        >
          <div
            v-for="item in copyList"
            :key="item[listKey]"
            class="popup-list-item"
            :class="{
              active: item.isActive,
              'active-bgc': isShowActiveBgc && item.isActive
            }"
            @click="selectOption(item)"
          >
            <!-- <slot name="content" :row="item">{{ item.code }}</slot> -->
            <div class="title-row list">
              <span class="f1">{{ item.prodId }}</span>
              <span class="f1">{{ item.chName }}</span>
              <span class="wd20">{{ item.repertoryNum }}</span>
              <span class="wd20">{{ item.unit }}</span>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div v-if="isShowFooter" class="footer">
      <slot name="footer">
        <van-button
          round
          size="small"
          class="footer-btn footer-reset"
          type="default"
          @click="onReset(false)"
          >重 置</van-button
        >
        <van-button
          round
          size="small"
          class="footer-btn footer-sure"
          type="info"
          @click="selectSureYes"
          >确 认</van-button
        >
      </slot>
    </div>
  </van-popup>
</template>

<script>
import { getCheckSpare } from "../api";

export default {
  name: "SelectSpareParts",
  components: {},
  model: {
    prop: "value",
    event: "inputValue"
  },
  props: {
    value: [String, Number, Array],
    otherValue: [String, Array],
    isShowConfrimIcon: {
      // 是否显示右上角的确认对号, 如果不显示则会把关闭放到这个位置
      type: Boolean,
      default: true
    },
    isShowFooter: {
      type: Boolean,
      default: false
    },
    isShowActiveBgc: {
      type: Boolean,
      default: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: true
    },
    multi: {
      // 是否多选
      type: Boolean,
      default: false
    },
    immediately: {
      // 是否立即执行
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: "未设置标题"
    },
    listKey: {
      type: String,
      default: "id"
    },
    // props: {
    //   type: Object,
    //   default: () => ({ label: "label", value: "value" })
    // },
    outputType: {
      type: String,
      default: ""
    },
    popupHeight: {
      type: String,
      default: "50%"
    },
    dataId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      popupStyle: {
        height: this.popupHeight
      },
      curSelect: [],
      query: {
        name: "",
        code: "",
        dataId: this.$route.params.id,
        dataType: 2,
        orgCode: this.$store.state.login.userInfo.orgCode,
        page: 1,
        size: 15
      },
      list: [],
      total: 0,
      copyList: [],
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false
    };
  },
  watch: {
    // list: {
    //   handler(newV) {
    //     newV.forEach(a => {
    //       this.$set(a, "isActive", false);
    //     });
    //     this.copyList = newV;
    //   },
    //   immediate: true
    // }
  },
  methods: {
    onRefresh() {
      this.query.page = 1;
      this.list = [];
      this.total = 0;
      this.getList();
    },
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.query.page = 1;
        this.total = 0;
        this.refreshing = false;
      }
      this.getList();
    },
    async getList() {
      this.loading = true;
      this.finished = false;
      if (this.refreshing) {
        this.refreshing = false;
      }
      const params = {
        ...this.query,
        dataId: this.dataId,
        dataType: 2
      };
      try {
        let list = [];
        let total = 0;
        const data = await getCheckSpare(params);
        list = data.list.map(i => {
          i.isActive = false;
          return i;
        });
        total = data.total;
        this.total = total;
        this.loadError = false;
        this.loading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.query.page++;
        } else {
          this.finished = true;
        }
        this.copyList = this.list;
        const curIds = this.curSelect.map(a => a[this.listKey]);
        this.setActiveArr(curIds);
      } catch (err) {
        this.loading = false;
        this.finished = true;
        this.loadError = true;
      }
    },
    onOpen() {
      // 再次打开需要清空过滤框
      this.query.code = "";
      this.query.name = "";
      this.onSearch();
      // 回显绑定的数据
      if (
        !this.value ||
        (Array.isArray(this.value) && this.value.length === 0)
      ) {
        // 清空选择
        this.selectAll(false);
        return;
      }
      // if (Array.isArray(this.value) || typeof this.value === "string") {
      //   if (this.multi) {
      //     let s =
      //       typeof this.value === "string" ? this.value.split(",") : this.value;
      //     this.curSelect = this.setActiveArr(s);
      //   } else {
      //     // 单选
      //     this.curSelect = this.setActive(this.value);
      //   }
      // } else {
      //   this.curSelect = this.setActive(this.value);
      // }
    },
    onReset() {
      this.$emit("reset");
    },
    selectAll(flag) {
      // 全选
      if (flag) {
        this.curSelect = this.setActiveArr(
          this.copyList.map(a => a[this.listKey])
        );
      } else {
        // 全不选
        this.curSelect = this.setActiveArr([]);
      }
    },
    onOpened() {},
    onClose() {
      this.$emit("update:visible", false);
      this.$emit("close");
    },
    onSearch() {
      // if (!this.query.code) {
      //   this.copyList = this.list;
      //   // 需要回显之前选中的数据
      //   const curIds = this.curSelect.map(a => a[this.listKey]);
      //   this.setActiveArr(curIds);
      //   return;
      // }
      // this.copyList = this.list.filter(a =>
      //   a[this.props.label].includes(this.query.code)
      // );
      this.query.page = 1;
      this.list = [];
      this.copyList = [];
      this.getList();
    },
    onCancel() {
      this.query.code = "";
      this.query.name = "";
      this.onSearch();
    },
    setActiveArr(curs) {
      const selectArr = [];
      this.copyList.forEach(item => {
        item.isActive = curs.find(
          i =>
            i === item[this.listKey] || i[this.listKey] === item[this.listKey]
        )
          ? true
          : false;
        if (item.isActive) {
          selectArr.push(item);
        }
      });
      console.log("selectArr", curs, selectArr);
      return selectArr;
    },
    setActive(curId) {
      const selectArr = [];
      this.copyList.forEach(item => {
        const r = item[this.listKey] === curId;
        item.isActive = r;
        if (r) {
          selectArr.push(item);
        }
      });
      return selectArr;
    },
    setIsActiveProp(obj, value) {
      this.$set(obj, "isActive", value);
    },
    selectOption(item) {
      if (!this.multi) {
        // 单选
        const first = this.curSelect[0];
        if (first && item[this.listKey] === first[this.listKey]) {
          item.isActive = false;
          this.curSelect = [{}];
        } else {
          item.isActive = true;
          this.setActive(item[this.listKey]);
          this.curSelect = [item];
        }
      } else {
        // 多选
        const index = this.curSelect.findIndex(
          a => a[this.listKey] === item[this.listKey]
        );
        if (index === -1) {
          item.isActive = true;
          this.curSelect.push(item);
        } else {
          const cur = this.copyList.find(
            a => a[this.listKey] === item[this.listKey]
          );
          cur.isActive = false;
          this.curSelect.splice(index, 1);
        }
      }
      if (this.immediately) {
        this.emitChange();
      }
    },
    emitChange(isNotEmit) {
      const r = this.curSelect.map(a => {
        return {
          ...a
          // value: a[this.props.value],
          // label: a[this.props.label]
        };
      });
      if (this.outputType === "string") {
        this.$emit("inputValue", r.map(a => a.value).toString());
      } else if (this.outputType === "array") {
        this.$emit(
          "inputValue",
          r.map(a => a.value)
        );
      }
      if (isNotEmit) {
        return r;
      } else {
        let _r = r;
        const otherValueType = Object.prototype.toString.call(this.otherValue);
        if (otherValueType === "[object String]") {
          _r = _r.map(a => a.label).join(",");
        }
        this.$emit("update:otherValue", _r);
        this.$emit("change", r);
      }
    },
    selectSure() {
      if (!this.immediately) {
        this.emitChange();
      }
      this.onClose();
    },
    selectSureYes() {
      const r = this.emitChange(true);
      this.$emit("sure");
      this.onClose();
    }
  }
};
</script>

<style lang="scss" scoped>
.select-popup-grade {
  .popup-con-header {
    height: 54px;
    line-height: 54px;
    .popup-con-header-title {
      color: #2e2e4d;
      margin: 0 44px;
      text-align: center;
    }
    .iconclose,
    .iconsure,
    .iconright {
      position: absolute;
      top: 0;
      width: 44px;
      height: 100%;
      text-align: center;
    }
    .iconclose {
      left: 0;
      color: #9496a3;
    }
    .iconsure {
      right: 0;
      color: #1676ff;
    }
    .iconright {
      right: 0;
    }
  }
  .search-bar {
    padding-top: 0;
  }
  .popup-list {
    position: absolute;
    top: 147px;
    bottom: 0;
    width: 100%;
    overflow: auto;
    .title-row {
      display: flex;
      padding: 14px 16px;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      .f1 {
        flex: 1;
      }
      .wd20 {
        width: 20%;
      }
      span {
        word-break: break-all;
      }
    }
    .title-row.list {
      padding: 0;
      border: none;
    }
    &.nofilter {
      top: 56px;
    }
    &.show-footer {
      bottom: 76px;
    }
    .popup-list-item {
      color: #2e2e4d;
      padding: 14px 16px;
      &.active {
        color: $--color-primary;
      }
      &.active-bgc {
        background-color: rgba(22, 118, 255, 0.1);
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    padding: 16px;
    z-index: 10;
    .footer-btn {
      flex: 1;
      text-align: center;
      box-sizing: border-box;
      height: 44px;
      &.footer-sure {
        margin-left: 16px;
      }
    }
  }
}
</style>

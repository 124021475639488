<template>
  <div>
    <van-nav-bar
      :title="form.status === 1 ? '上传记录' : '维修记录详情'"
      :border="false"
      fixed
      left-arrow
      @click-left="navBack"
    >
      <template #right>
        <van-icon
          v-if="form.status === 1"
          size="24"
          class-prefix="iconfont"
          name="tianjiagongzuobuzhou"
          @click="addExternalRepairs"
        />
        <van-icon
          v-else
          size="24"
          class-prefix="iconfont"
          name="liucheng"
          @click="approveProcess"
        />
      </template>
    </van-nav-bar>
    <div class="content">
      <div class="workorder-content">
        <div class="detail-box" :class="{ 'pack-up': baseInfoPackUp }">
          <div class="detail-item order-no">
            <span class="label">工单编号：</span>
            <span class="text">{{ workorderInfo.workNo }}</span>
          </div>
          <div class="detail-item">
            <span class="label">工单名称：</span>
            <span class="text">{{ workorderInfo.name }}</span>
          </div>
          <div class="detail-item">
            <span class="label">工单类型：</span>
            <span class="text">{{ workorderInfo.ticketsTypeName }}</span>
          </div>

          <div class="detail-item">
            <span class="label">计划维修时间：</span>
            <span class="text">{{ workorderInfo.planMaintenanceDate }}</span>
          </div>
          <div class="detail-item">
            <span class="label">是否应急：</span>
            <span class="text">{{
              +workorderInfo.emergencyType === 1 ? "是" : "否"
            }}</span>
          </div>
          <div class="detail-item">
            <span class="label">关联故障：</span>
            <span class="text">{{ workorderInfo.deviceFaultName }}</span>
          </div>
          <div class="detail-item">
            <span class="label">故障设备：</span>
            <span class="text">{{ workorderInfo.equipmentInfo }}</span>
          </div>
          <div class="detail-item">
            <span class="label">维修部门：</span>
            <span class="text">{{
              workorderInfo.maintenanceDepartmentName
            }}</span>
          </div>
          <div class="detail-item">
            <span class="label">预估费用：</span>
            <span class="text">{{ workorderInfo.cost }}</span>
          </div>
          <div class="detail-item">
            <span class="label">申报人：</span>
            <span class="text">{{ workorderInfo.declareCuserName }}</span>
          </div>
          <div class="detail-item">
            <span class="label">申报部门：</span>
            <span class="text">{{ workorderInfo.departmentName }}</span>
          </div>
          <div class="detail-item">
            <span class="label">送审时间：</span>
            <span class="text">{{ workorderInfo.createTime }}</span>
          </div>
        </div>
        <div class="exp-box">
          <span v-show="baseInfoPackUp" @click="baseInfoPackUp = false">
            <img
              class="triangle-colse"
              src="@/assets/images/equipment/triangle.png"
            />
          </span>
          <span v-show="!baseInfoPackUp" @click="baseInfoPackUp = true">
            <img src="@/assets/images/equipment/triangle.png" />
          </span>
        </div>
      </div>
      <div class="outSide">
        <div class="inSide">
          <template v-if="isShowAuditBtn">
            <van-form ref="approveForm" class="form">
              <van-field
                v-model="form.operationStatus"
                name="operationStatus"
                :label-class="'equipment-title'"
                label="审批"
                placeholder="请选择"
                required
                input-align="right"
                error-message-align="right"
                :disabled="!editable && !approveEditable"
                :rules="[
                  {
                    required: true,
                    message: '请选择',
                    trigger: 'onChange'
                  }
                ]"
              >
                <template #input>
                  <van-radio-group
                    v-model="form.operationStatus"
                    direction="horizontal"
                    :disabled="!editable && !approveEditable"
                  >
                    <van-radio name="1">通过</van-radio>
                    <van-radio name="0">退回</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <van-field
                v-model="form.approveContent"
                clickable
                name="approveContent"
                label="意见"
                placeholder="请输入内容"
                maxlength="500"
                clearable
                required
                :disabled="!editable && !approveEditable"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: form.operationStatus === '0',
                    message: '',
                    trigger: 'onChange'
                  }
                ]"
              />
            </van-form>
          </template>
          <div class="exp-box">
            <span v-show="formInfoPackUp" @click="formInfoPackUp = false">
              <img src="@/assets/images/equipment/triangle.png" />
            </span>
            <span v-show="!formInfoPackUp" @click="formInfoPackUp = true">
              <img
                class="triangle-colse"
                src="@/assets/images/equipment/triangle.png"
              />
            </span>
          </div>
          <van-form ref="form" class="form">
            <div class=" equipment-title inside-title">
              <span>维修部门</span>
              <span class="equipment-title-name">{{
                form.departmentName
              }}</span>
            </div>
            <div v-show="formInfoPackUp">
              <van-field
                :value="(form.maintenanceUserName || []).toString()"
                clickable
                :is-link="editable"
                name="maintenanceUserName"
                label="维修人"
                placeholder="请选择"
                :disabled="!editable"
                :required="editable"
                clearable
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: true,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
                @click="maintainPersonSelect()"
              />
              <van-field
                v-model="form.startDate"
                readonly
                clickable
                :is-link="editable"
                name="startDate"
                :disabled="!editable"
                label="开始时间"
                placeholder="请选择"
                :required="editable"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: true,
                    message: ' ',
                    trigger: 'onChange'
                  },
                  {
                    validator: startValidator,
                    message: '开始时间不能晚于结束时间',
                    trigger: 'onChange'
                  }
                ]"
                @click="planTimeClick('startDate')"
              />
              <van-field
                v-model="form.endDate"
                readonly
                clickable
                :is-link="editable"
                name="endDate"
                :disabled="!editable"
                label="结束时间"
                placeholder="请选择"
                :required="editable"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: true,
                    message: ' ',
                    trigger: 'onChange'
                  },
                  {
                    validator: endValidator,
                    message: '结束时间不能早于开始时间',
                    trigger: 'onChange'
                  }
                ]"
                @click="planTimeClick('endDate')"
              />
              <van-field
                readonly
                clickable
                :is-link="editable"
                name="partList"
                :disabled="!editable"
                label="备件需求"
                :placeholder="editable ? '添加' : '--'"
                input-align="right"
                error-message-align="right"
                @click="sparePartsClick()"
              />
              <div class="spare-parts-box">
                <van-field
                  v-for="(item, idx) of form.partList"
                  :key="idx"
                  v-model="item.needNum"
                  clickable
                  :name="item.needNum"
                  :disabled="!editable"
                  :label="item.name"
                  placeholder="请输入数量"
                  :required="editable"
                  input-align="right"
                  error-message-align="right"
                  :rules="[
                    {
                      required: true,
                      message: ' ',
                      trigger: 'onChange'
                    }
                  ]"
                >
                  <template #label>
                    <span class="part-label">{{ `${item.name}` }}</span>
                  </template>
                  <template #right-icon
                    ><span class="part-unit">{{ item.unit }}</span></template
                  >
                </van-field>
              </div>
              <van-field
                v-model="form.workTime"
                clickable
                name="workTime"
                label="工时(人*小时)"
                label-width="100"
                :placeholder="editable ? '请输入内容' : '--'"
                :disabled="!editable"
                clearable
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    pattern: /^-?(^\d{1,23})*(\.\d{1,4})?$/,
                    message: '仅能输入数字，允许输入23位整数 小数4位',
                    trigger: 'onChange'
                  }
                ]"
              />
              <van-field
                v-model="form.cost"
                clickable
                name="cost"
                label="维修费用(元)"
                :placeholder="editable ? '请输入内容' : '--'"
                :disabled="!editable"
                clearable
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    pattern: /^-?(^\d{1,23})*(\.\d{1,6})?$/,
                    message: '仅能输入数字，允许输入23位整数 小数6位',
                    trigger: 'onChange'
                  }
                ]"
              />
              <van-field
                v-model="form.score"
                clickable
                name="score"
                label="评分"
                :placeholder="editable ? '请输入内容' : '--'"
                :disabled="!editable"
                maxlength="20"
                show-word-limit
                clearable
                input-align="right"
                error-message-align="right"
              />
              <van-field
                v-model="form.processRecord"
                clickable
                name="processRecord"
                label="配件更换"
                placeholder="请输入内容"
                maxlength="500"
                clearable
                :required="editable"
                :disabled="!editable"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: true,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
              <van-field
                v-model="form.subsidiaryWork"
                clickable
                name="subsidiaryWork"
                label="辅助工作"
                :placeholder="editable ? '请输入内容' : '--'"
                maxlength="500"
                clearable
                :disabled="!editable"
                input-align="right"
                error-message-align="right"
              />
              <van-field
                v-model="form.maintenanceExplain"
                clickable
                name="maintenanceExplain"
                label="检修说明"
                :placeholder="editable ? '请输入内容' : '--'"
                maxlength="500"
                clearable
                :disabled="!editable"
                input-align="right"
                error-message-align="right"
              />
              <van-field
                v-model="form.maintenanceAnalyse"
                clickable
                name="maintenanceAnalyse"
                label="维修分析"
                :placeholder="editable ? '请输入内容' : '--'"
                maxlength="500"
                clearable
                :disabled="!editable"
                input-align="right"
                error-message-align="right"
              />

              <!-- 上传附件	 -->
              <fileUploaderEncap
                v-model="form.fileList"
                accept="*"
                :max-size="1024 * 1024 * 100"
                tip="格式不限,单个文件不超过100MB,仅可以上传5个文件"
                :unable-upload="!editable"
              >
              </fileUploaderEncap>
            </div>
          </van-form>
        </div>
      </div>
      <div v-for="item in outSideInfo" :key="item.id" class="cardBox">
        <div class="top">
          <div class=" equipment-title">
            <span>外部维修部门</span>
            <span class="equipment-title-name">{{
              item.contractorName | valCheck
            }}</span>
            <van-button
              type="info"
              size="small"
              class="outside-edit"
              @click="editExternalRepairs(item)"
              >编辑
            </van-button>
          </div>
          <div class="exp-box">
            <span
              v-show="item.outSideInfoPackUp"
              @click="item.outSideInfoPackUp = false"
            >
              <img
                class="triangle-colse"
                src="@/assets/images/equipment/triangle.png"
              />
            </span>
            <span
              v-show="!item.outSideInfoPackUp"
              @click="item.outSideInfoPackUp = true"
            >
              <img src="@/assets/images/equipment/triangle.png" />
            </span>
          </div>
        </div>
        <div v-show="!item.outSideInfoPackUp" class="bottom">
          <div class="outSideDetail">
            <span>工时（人/小时）</span>
            <span>{{ item.workTime | valCheck }}</span>
          </div>
          <div class="outSideDetail">
            <span>开始时间</span>
            <span>{{ item.startDate | valCheck }}</span>
          </div>
          <div class="outSideDetail">
            <span>结束时间</span>
            <span>{{ item.endDate | valCheck }}</span>
          </div>
          <div class="outSideDetail">
            <span>维修人</span>
            <span>{{ item.maintenanceUserName | valCheck }}</span>
          </div>
          <div class="outSideDetail">
            <span>备件需求</span>
            <span>{{ item.reservePartNeed | valCheck }}</span>
          </div>
          <div class="outSideDetail">
            <span>评分</span>
            <span>{{ item.score | valCheck }}</span>
          </div>
          <div class="outSideDetail">
            <span>维修分析</span>
            <span>{{ item.maintenanceAnalyse | valCheck }}</span>
          </div>
          <div class="outSideDetail">
            <span class="longText">检修说明</span>
            <span>{{ item.maintenanceExplain | valCheck }}</span>
          </div>
          <div class="outSideDetail">
            <span>过程记录</span>
            <span>{{ item.processRecord | valCheck }}</span>
          </div>
          <div class="outSideDetail">
            <span>辅助工作</span>
            <span>{{ item.subsidiaryWork | valCheck }}</span>
          </div>
          <div class="outSideDetail">
            <span>附件</span>
            <span>
              <FileUploader
                v-model="item.fileList"
                :max-count="5"
                :accept="FILE_ACCEPT_TYPES.IMAGE"
                :unable-upload="true"
              />
            </span>
          </div>
          <div class="outSideDetail">
            <span>维修费用</span>
            <span class="fee">{{ item.cost | valCheck }}</span>
          </div>
        </div>
      </div>
      <bottom-fixed-box>
        <template v-if="form.status === 1">
          <van-button class="btn-default" @click="save">保存</van-button>
          <van-button class="btn-primary" @click="submit">提交</van-button>
        </template>
        <template v-if="isShowAuditBtn">
          <van-button class="btn-primary" @click="approve">审批</van-button>
        </template>
      </bottom-fixed-box>
    </div>

    <!-- <select-popup
      :choose-value="form.maintenanceUser"
      :data="maintainPersonSltData"
      title="维修人"
      :visible="maintainPersonVisiable"
      :popup-style="{
        height: '70vh'
      }"
      :multiple="true"
      @close="maintainPersonVisiable = false"
      @confirm="maintainPersonChange"
    /> -->
    <select-popup-grade
      v-model="form.maintenanceUser"
      :immediately="false"
      multi
      list-key="value"
      title="维修人"
      :list.sync="maintainPersonSltData"
      :visible="maintainPersonVisiable"
      popup-height="70%"
      @change="popupGradeChange"
      @close="maintainPersonVisiable = false"
    />
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker
        v-model="initTime"
        type="date"
        @confirm="onDateimeConfirm"
        @cancel="showTimePicker = false"
      />
    </van-popup>
    <select-spare-parts
      v-model="form.partList"
      :data-id="id"
      :immediately="false"
      multi
      list-key="id"
      title="选择备件"
      :visible="sparePartsVisiable"
      popup-height="70%"
      @change="sparePartsChange"
      @close="sparePartsVisiable = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import FileUploader from "@/components/fileuploader/FileUploader";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";
import { parseTime } from "@/utils";
import {
  getOutSideInfo,
  getWorkorderInfo,
  getMaintenanceInfo,
  updateMaintenanceRecord,
  getMaintenanceRecordPermission, //查询是否有审批权限
  getMaintenanceRecordCurrentNode, //查询当前正在执行的节点
  approveMaintenance
} from "@/api/psm/workorder";
import authApi from "@/api/authorization";
import selectSpareParts from "./components/selectSpareParts";
import fileUploaderEncap from "@/components/fileUploaderEncap.vue";

export default {
  components: {
    FileUploader,
    BottomFixedBox,
    selectSpareParts,
    fileUploaderEncap
  },
  data() {
    return {
      approveShow: false,
      FILE_ACCEPT_TYPES,
      workorderId: this.$route.params.workorderId,
      id: this.$route.params.id,
      type: this.$route.params.type,
      editable: false,
      baseInfoPackUp: true,
      formInfoPackUp: true,
      approveEditable: false,
      isShowAuditBtn: false,
      workorderInfo: {
        workNo: "", // 工单编号
        name: "", // 工单名称
        ticketsTypeId: "", // 工单类型
        ticketsTypeName: "",
        planMaintenanceDate: "", // 计划维修时间
        emergencyType: "", // 是否应急维修
        deviceFaultId: "", // 关联故障
        deviceFaultName: "",
        equipmentId: "", // 故障关联设备id,
        equipmentInfo: "", // 设备位号+名称
        maintenanceDepartment: "", // 维修部门
        _maintenanceDepartment: [],
        maintenanceDepartmentName: "", // 维修部门名称
        createUserName: "", // 创建人
        createTime: "" // 创建时间
      },
      form: {
        // 审批
        operationStatus: "", // 审批
        approveContent: "", // 意见
        currentNodeId: "",
        // 表单
        flag: 1,
        status: "",
        department: "", // 维修部门
        departmentName: "",
        maintenanceUser: [], // 维修人
        maintenanceUserName: [],
        startDate: "", // 开始时间
        endDate: "", // 结束时间
        partList: [], // 备件需求
        workTime: "", // 工时
        cost: "", // 维修费用
        score: "", // 评分
        processRecord: "", // 过程记录
        subsidiaryWork: "", // 辅助工作
        maintenanceExplain: "", // 检修说明
        maintenanceAnalyse: "", // 维修分析
        fileList: [] // 附件
      },
      maintainPersonSltData: [],
      outSideInfo: [],
      maintainPersonVisiable: false,
      showTimePicker: false,
      initTime: "",
      planTimeCurProp: "",
      sparePartsVisiable: false
    };
  },
  filters: {
    valCheck(val) {
      return val ? val : "--";
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getPageInfo();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    editExternalRepairs(item) {
      this.$router.push({
        name: "maintenanceExternalRepairsEdit",
        params: {
          contractorInfo: item,
          recordId: this.id,
          workorderId: this.workorderId
        }
      });
    },
    addExternalRepairs() {
      this.$router.push({
        name: "maintenanceExternalRepairsAdd",
        params: { recordId: this.id, workorderId: this.workorderId }
      });
    },
    async getPageInfo() {
      this.getWorkorderInfo();
      if (this.id) {
        await this.getMaintenanceInfo();
        var data = await getOutSideInfo({ recordId: this.$route.params.id });
        this.outSideInfo = data.map(item => {
          return {
            ...item,
            outSideInfoPackUp: true
          };
        });
        this.getMaintenanceRecordPermission();
        this.getSelectUsersTypes();
      }
    },
    getWorkorderInfo() {
      getWorkorderInfo(this.workorderId).then(res => {
        res._maintenanceDepartment = res.maintenanceDepartment.split(",");
        this.workorderInfo = {
          ...res
        };
      });
    },
    async getMaintenanceInfo() {
      const data = await getMaintenanceInfo(this.id);
      data.startDate = data.startDate || "";
      data.endDate = data.endDate || "";
      data.processRecord = data.processRecord || "";
      data.partList = (data.partList || []).map(i => {
        i.needNum = i.needTotal;
        i.reserveId = i.id;
        return i;
      });
      this.editable = data.status === 1;
      this.form = data;
    },
    getMaintenanceRecordPermission() {
      const params = {
        id: this.id,
        userId: this.userInfo.id
      };
      Promise.all([
        getMaintenanceRecordPermission(params),
        getMaintenanceRecordCurrentNode({ id: this.id })
      ])
        .then(res => {
          const [one, two] = res;
          // 设置是否有审核权限
          console.log(res);
          this.isShowAuditBtn = one && two && this.form.status == 2;
          this.approveEditable = this.isShowAuditBtn;
          this.form.currentNodeId = two;
        })
        .catch(err => {
          this.$toast.fail("查询当前正在执行的节点失败！请稍后再试！");
        });
    },
    async getSelectUsersTypes() {
      const params = {
        orgCode: this.userInfo.orgCode,
        department: this.form.department
      };
      this.maintainPersonSltData = await authApi.getSelectUsersTypes(params);
    },
    planTimeClick(prop) {
      if (!this.editable) return;
      this.planTimeCurProp = prop;
      const datetime = this.form[prop];
      this.initTime = datetime ? new Date(datetime) : new Date();
      this.showTimePicker = true;
    },
    onDateimeConfirm(time) {
      this.form[this.planTimeCurProp] = parseTime(time, "{y}-{m}-{d}");
      this.showTimePicker = false;
    },
    navBack() {
      if (this.editable || this.approveEditable) {
        this.$dialog
          .confirm({
            title: "提醒",
            message: "数据尚未保存，确认返回？"
          })
          .then(() => {
            this.$router.back();
          });
        return;
      }
      this.$router.back();
    },
    approveProcess() {
      this.$router.push({
        name: "maintenanceRecordsApproveProcessRx",
        params: {
          id: this.id
        }
      });
    },
    maintainPersonSelect() {
      if (!this.editable) return;
      this.maintainPersonVisiable = true;
    },
    maintainPersonChange(val) {},
    popupGradeChange(v) {
      this.form.maintenanceUser = v.map(i => i.value);
      this.form.maintenanceUserName = v.map(i => i.label);
    },
    endValidator(val) {
      if (!val || !this.form.startDate) return true;
      const endTime = Date.parse(new Date(val));
      const startTime = Date.parse(new Date(this.form.startDate));
      return endTime >= startTime;
    },
    startValidator(val) {
      if (!val || !this.form.endDate) return true;
      const startTime = Date.parse(new Date(val));
      const endTime = Date.parse(new Date(this.form.endDate));
      return endTime >= startTime;
    },
    save() {
      this.$refs.form.validate().then(async () => {
        this.form.id = this.id;
        this.form.flag = 1;
        this.form.partList = this.form.partList.map(i => {
          i.reserveId = i.id;
          return i;
        });
        await updateMaintenanceRecord(this.form);
        this.$toast.success("保存成功");
        this.$router.back();
      });
    },
    submit() {
      this.$refs.form.validate().then(() => {
        this.$dialog
          .confirm({
            title: "提醒",
            message: "提交后不可修改，确认提交？"
          })
          .then(async () => {
            this.form.id = this.id;
            this.form.flag = 2;
            this.form.partList = this.form.partList.map(i => {
              i.reserveId = i.id;
              return i;
            });
            await updateMaintenanceRecord(this.form);
            this.$toast.success("提交成功");
            this.$router.back();
          });
      });
    },

    popupClose() {
      // this.$refs.approveForm.resetValidation()
      this.form.operationStatus = "";
      this.form.approveContent = "";
    },
    approve() {
      // this.$refs.form.validate().then(() => {
      this.$refs.approveForm.validate().then(() => {
        this.$dialog
          .confirm({
            title: "提醒",
            message: "确认提交？"
          })
          .then(async () => {
            const params = {
              maintenanceRecordId: this.id, // 工单id
              operationUserId: this.userInfo.id, // 当前操作人id
              department: this.userInfo.department,
              currentNodeId: this.form.currentNodeId, // 当前节点id
              operationStatus: this.form.operationStatus, // 操作意见, 1: 通过, 0: 不通过
              approveContent: this.form.approveContent // 审批信息
            };
            await approveMaintenance(params);
            this.isShowAuditBtn = false;
            this.approveEditable = false;
            this.form.currentNodeId = "";
            this.approveShow = false;
            this.getPageInfo();
            this.$toast.success("审批成功");
            // this.$router.back();
          });
      });
      // });
    },
    sparePartsClick() {
      if (!this.editable) return;
      this.sparePartsVisiable = true;
    },
    sparePartsChange(v) {
      let val = [];
      for (let i = 0; i < v.length; i++) {
        val.push({
          ...v[i],
          code: v[i].prodId,
          name: v[i].chName,
          unit: v[i].unitId,
          unitName: v[i].unit
        });
      }

      this.form.partList.push(
        ...val.filter(i => !this.form.partList.find(f => i.id === f.id))
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  height: 100vh;
  margin-top: 58px;
  overflow: auto;
  background: #eceef2;

  .part-label {
    display: inline-block;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .part-unit {
    display: inline-block;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.popBox {
  padding: 24px 16px 0;

  .popTitle {
    margin-bottom: 32px;
    font-weight: 500;
    color: #2e2e4d;
    font-size: 16px;
  }
}

.outSide {
  background-color: #ffffff;
  margin: 8px 0;

  .inSide {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
  }
}

.exp-box {
  position: absolute;
  top: 10px;
  left: 3px;

  .triangle-colse {
    transform: rotate(-90deg);
  }
}

.cardBox {
  position: relative;
  padding: 12px 16px 12px;
  margin-bottom: 8px;
  background: #ffffff;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;

  .outSideDetail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .longText {
      width: 200px;
    }
  }

  .top {
    .van-row {
      line-height: 20px;
      margin-bottom: 12px;
    }
  }

  .bottom {
    color: #8c8f97;
    margin-top: 12px;

    .fee {
      color: #2e2e4d;
      font-weight: 500;
    }
  }
}

.equipment-title {
  font-size: 16px !important;
  font-weight: 600 !important;
  justify-content: left !important;

  .equipment-title-name {
    margin-left: 12px;
    font-size: 12px;
    color: #a0a2a6;
  }

  .outside-edit {
    float: right;
    height: 24px;
    font-size: 12px;
  }
}

.inside-title {
  padding: 12px 16px;
}

.workorder-content {
  position: relative;
  padding: 12px 16px 16px;
  background-color: #ffffff;

  .detail-box.pack-up {
    height: 120px;
  }

  .detail-box {
    margin: 0 0 8px;
    overflow: hidden;

    .order-no {
      font-size: 16px !important;
      font-weight: 600 !important;

      > span {
        width: auto !important;
        color: #2e2e4d !important;
      }
    }

    .detail-item {
      display: flex;
      align-items: center;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;

      .label {
        width: 98px;
        color: #8c8f97;
      }

      .text {
        flex: 1;
        word-break: break-word;
        color: #2e2e4d;
      }

      .executor {
        flex: initial;
        // display: inline-block;
        padding: 2px 6px;
        background: #eaeff5;
        border-radius: 2px;
      }

      .associate {
        // border: 1px solid #c7c9d0;
        // padding: 2px 4px;
        .van-cell {
          border: 1px solid #c7c9d0;
          padding: 2px 4px;
        }
      }

      .switch-account {
        display: inline-block;
        font-size: 12px;
        margin: 0 10px 0;
        padding: 4px 6px 3px;
        background-color: #7f7f7f;
        border-radius: 15px;
        color: #fff;
      }
    }

    ::v-deep {
      .c-van-field::before {
        left: -8px;
      }

      .c-van-field {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;

        .c-label {
          font-size: 14px;
          color: #8c8f97;
        }

        .c-data-tiem {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}

.form {
  &.popForm {
    padding: 0 0 20px;
  }

  .spare-parts-box {
    ::v-deep {
      .van-cell {
        background-color: #f2f5fa;
      }

      .van-field__label {
        width: 130px;
      }

      .van-field__body {
        align-items: flex-start;
      }

      .van-field__right-icon {
        max-width: 60px;
        word-break: break-all;
      }
    }
  }

  .file {
    ::v-deep {
      .van-field__control {
        text-align: right;
      }
    }
  }
}
</style>
